import { DocumentNode } from '@apollo/client/index.js';
import AutocompleteField, {
  Option,
  Props as AutocompleteFieldProps,
} from '@engined/client/components/forms/fields/AutocompleteField.js';
import { testId } from '@engined/client/helpers/test.js';
import useAsyncDebounce from '@engined/client/hooks/useAsyncDebounce.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useMounted from '@engined/client/hooks/useMounted.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { styled } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

type fixme = any;

type Variables = { [name: string]: fixme };

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

Root.displayName = 'Root';

interface OwnProps<Multiple extends boolean> {
  query: DocumentNode;
  dataToOptions(data: fixme): Option[];
  variables?: Variables | ((value: Multiple extends true ? Option[] : Option) => Variables);
  skip?: boolean;
  addButton?: React.ReactNode;
  editButton?: React.ReactNode;
  control?: Control;
}

export type Props<
  TOption extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = OwnProps<Multiple> &
  Omit<AutocompleteFieldProps<TOption, Multiple, DisableClearable, FreeSolo>, 'options' | 'loading' | 'onInputChange'>;

const sx = { flex: 1 };

function RelationField<
  TOption extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  query,
  dataToOptions,
  variables,
  onChange,
  skip,
  onClose,
  multiple,
  addButton,
  editButton,
  ...rest
}: Props<TOption, Multiple, DisableClearable, FreeSolo>) {
  const fieldValue = useWatch({ name: rest.name, control: rest.control });
  const [selectQuery, setSelectQuery] = useState<string>('');
  const mounted = useMounted();

  const onInputChange = useAsyncDebounce<
    Exclude<AutocompleteFieldProps<TOption, Multiple, DisableClearable, FreeSolo>['onInputChange'], undefined>
  >((event, value, reason) => {
    // Ignore if component is unmounted
    if (!mounted.current) {
      return;
    }
    setSelectQuery(reason === 'reset' ? '' : value);
  }, 300);

  const allVariables = useMemo(() => {
    return {
      query: `%${selectQuery}%`,
      ...(multiple && (fieldValue as Option[]).length
        ? { value: (fieldValue as Option[]).map((v) => v.value) }
        : !multiple && fieldValue
        ? { value: [(fieldValue as Option).value] }
        : {}),
      ...(variables ? (typeof variables === 'function' ? variables(fieldValue) : variables) : {}),
    };
  }, [variables, selectQuery, multiple, fieldValue]);

  const { loading, data, previousData } = useQuery(query, {
    variables: allVariables,
    fetchPolicy: 'cache-and-network',
    skip: skip || rest.disabled,
  });

  const options = useMemo<Option[]>(() => {
    let newOptions: Option[] = [];

    const dataOrPrevious = data ?? previousData;
    if (dataOrPrevious) {
      newOptions = [...newOptions, ...dataToOptions(dataOrPrevious)];
    }

    if (multiple) {
      const prepend = (fieldValue as Option[]).filter((v) => !newOptions.find((o) => o.value === v.value));
      newOptions = [...prepend, ...newOptions];
    } else if (fieldValue) {
      const hasValue = newOptions.find((o) => o.value === (fieldValue as Option).value);
      if (!hasValue) {
        newOptions = [fieldValue as Option, ...newOptions];
      }
    }

    return newOptions;
  }, [data, previousData, dataToOptions, fieldValue, multiple]);

  const onCloseCallback = useEventCallback<
    Exclude<Props<TOption, Multiple, DisableClearable, FreeSolo>['onClose'], undefined>
  >((event, value, ...rest) => {
    setSelectQuery('');
    if (onClose) {
      onClose(event, value, ...rest);
    }
  });

  return (
    <Root {...testId(`relation-field-${rest.name}`)}>
      <AutocompleteField
        {...rest}
        className={rest.className}
        multiple={multiple}
        options={options}
        loading={loading}
        onInputChange={onInputChange}
        onChange={onChange}
        onClose={onCloseCallback}
        sx={sx}
      />
      {addButton}
      {!multiple && fieldValue ? editButton : null}
    </Root>
  );
}

RelationField.displayName = 'RelationField';

export default React.memo(RelationField);
